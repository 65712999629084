<template>
  <b-modal
    id="modal-finish-task"
    ref="refModal"
    :title="t('Hoàn thành công việc')"
    :ok-title="isSubmitting ? t('Đang lưu') : t('Lưu')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    size="lg"
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-alert
        show
        variant="primary"
      >
        <div class="alert-body">
          <feather-icon
            class="mr-25"
            icon="StarIcon"
          />
          <span class="ml-25">{{ t('Bạn đang tiến hành xác nhận hoàn thành công việc') }}: <strong>{{ itemLocal.title }}</strong>.</span>
        </div>
      </b-alert>
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >

        <b-row>
          <b-col cols="12">
            <!-- due Date -->
            <time-picker
              v-model="itemLocal.finishDate"
              :default-value="itemLocal.finishDate"
              :label="t('Thời gian hoàn thành')"
            />
          </b-col>
          <b-col />
        </b-row>

        <!-- Description -->
        <validation-provider
          #default="validationContext"
          name="Mô tả"
          rules="required"
        >
          <b-form-group
            :label="t('Mô tả')"
          >
            <b-form-textarea
              v-model="itemLocal.finishNote"
              :state="getValidationState(validationContext)"
              rows="3"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <media-view
          v-model="itemLocal.finishAttachments"
          :label="t('Đính kèm')"
          :initial-value="itemLocal.finishAttachments"
        />

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormTextarea,
  BForm,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BAlert,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import TimePicker from '@/views/components/TimePicker.vue';
import MediaView from '@/views/components/media/MediaView.vue';
import useFinishTaskModal from './useFinishTaskModal';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormTextarea,
    BForm,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BAlert,

    TimePicker,
    MediaView,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      t,
    } = useFinishTaskModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      t,
    };
  },
};
</script>
