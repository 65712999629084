<template>
  <b-form-group
    :label="label"
    :state="state"
  >
    <template
      v-if="label"
      v-slot:label
    >
      {{ label }}
      <span
        v-if="required"
        class="text-danger"
      >
        (*)
      </span>
    </template>
    <flat-pickr
      v-model="date"
      :placeholder="placeholder"
      class="form-control"
      :config="config"
      @on-change="onChange"
      @input="onChange"
    />
    <small class="text-danger">{{ error }}</small>
  </b-form-group>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import { BFormGroup } from 'bootstrap-vue';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    BFormGroup,
    flatPickr,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: this.defaultValue,
    };
  },
  watch: {
    defaultValue(val) {
      this.date = val;
    },
  },
  methods: {
    onChange() {
      this.$emit('input', this.date);
    },
    onClose() {},
  },
  setup() {
    const config = {
      dateFormat: 'd-m-Y H:i',
      enableTime: true,
    };

    const { t } = useI18nUtils();

    return { config, t };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
