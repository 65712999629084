<!-- eslint-disable import/no-cycle -->
<template>
  <div class="task-list-container">
    <b-row
      v-if="analytics"
      class="match-height"
    >

      <b-col>
        <card-statistic
          icon="ToolIcon"
          :value="analytics.newTasks"
          :title="t('Chưa làm')"
          color="secondary"
        />
      </b-col>
      <b-col>
        <card-statistic
          icon="ToolIcon"
          color="warning"
          :value="analytics.inProgressTasks"
          :title="t('Đang làm')"
        />
      </b-col>
      <b-col>
        <card-statistic
          icon="ToolIcon"
          color="success"
          :value="analytics.completeTasks"
          :title="t('Chờ nghiệm thu')"
        />
      </b-col>
      <b-col>
        <card-statistic
          icon="ToolIcon"
          color="primary"
          :value="analytics.reviewTasks"
          :title="t('Đã nghiệm thu')"
        />
      </b-col>
      <b-col>
        <card-statistic
          icon="ToolIcon"
          color="info"
          :value="analytics.failTasks"
          :title="t('Không đạt')"
        />
      </b-col>
      <b-col>
        <card-statistic
          color="danger"
          icon="ToolIcon"
          :value="analytics.expiredTasks"
          :title="t('Quá hạn')"
        />
      </b-col>

    </b-row>
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ t('Công việc') }}</b-card-title>
        <crud-buttons
          :name="t('Công việc')"
          :selected-rows="selectedRows"
          modal="modal-task"
          :enable-add="$can('create', 'task')"
          :hide-delete="!$can('delete', 'task')"
          :show-export="$can('read', 'task')"
          @on-delete="onDelete"
          @on-add="resetItem"
          @on-export="exportData"
        />
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col>
            <select-task-status v-model="status" />
          </b-col>
          <b-col>
            <select-task-type
              v-model="type"
              :enable-add="false"
              :label="null"
            />
          </b-col>
          <b-col>
            <select-apartment v-model="apartment" />
          </b-col>
          <b-col>
            <select-room
              v-model="room"
              :apartment="apartment"
            />
          </b-col>

        </b-row>
        <b-row>
          <b-col>
            <select-manager
              v-model="performer"
              :include-owner="true"
            />
          </b-col>
          <b-col>
            <select-date
              v-model="startDate"
              :placeholder="t('Ngày bắt đầu')"
            />
          </b-col>
          <b-col>
            <select-date
              v-model="endDate"
              :placeholder="t('Ngày kết thúc')"
            />
          </b-col>
          <b-col>
            <b-form-input
              v-model="searchTerm"
              :placeholder="t('Tìm kiếm')"
              debounce="1000"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              style-class="vgt-table striped bordered"
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: false,
              }"
              :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: serverParams.perPage,
              }"
              @on-selected-rows-change="selectionChanged"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="loadingContent">
                <b-spinner
                  label="Loading"
                  type="grow"
                />
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Code -->
                <span v-if="props.column.field === 'code'">
                  <b-link
                    v-b-modal.modal-task-detail
                    class="font-weight-bold"
                    @click="onEditItem(props.row)"
                  >
                    {{ props.row.code }}
                  </b-link>
                </span>

                <!-- Column: Default -->
                <span v-else-if="props.column.field === 'title'">
                  <div>
                    <div>{{ props.row.title }}</div>
                    <div class="text-muted">{{ props.row.description }}</div>
                    <b-badge
                      v-if="props.row.priorityObject"
                      pill
                      :variant="`light-${props.row.priorityObject.variant}`"
                    >
                      {{ props.row.priorityObject.name }}
                    </b-badge>
                  </div>
                </span>

                <!-- Column: Default -->
                <span v-else-if="props.column.field === 'type.name'">
                  <b-badge
                    v-if="props.row.type"
                    variant="light-warning font-small-1"
                  >
                    {{ props.row.type.name }}
                  </b-badge>
                </span>
                <span v-else-if="props.column.field === 'performers'">
                  {{ resolvePerformerNames(props.row) }}
                </span>
                <span v-else-if="props.column.field === 'dueDate'">
                  {{ parseDateToString(props.row.dueDate, 'DD-MM-YYYY HH:mm') }}
                </span>

                <!-- Column: status -->
                <span v-else-if="props.column.field === 'status'">
                  <b-badge
                    pill
                    :variant="props.row.status.variant"
                  >
                    {{ props.row.status.title }}
                  </b-badge>
                  <b-badge
                    v-if="props.row.isOverDue"
                    class="ml-50"
                    pill
                    variant="light-danger"
                  >
                    {{ t('Quá hạn') }}
                  </b-badge>

                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <table-actions
                    :name="t('công việc')"
                    modal="modal-task"
                    :disable-edit="parseInt(props.row.status.value, 10) === 4"
                    :hide-delete="!$can('delete', 'task')"
                    :hide-edit="!$can('update', 'task')"
                    @on-delete="onDelete(props.row)"
                    @on-edit="onEditItem(props.row)"
                  >
                    <template
                      slot="other-buttons"
                    >
                      <b-button
                        v-if="props.row.status.value === '1' && $can('pick', 'task')"
                        v-b-tooltip.hover.v-warning
                        variant="warning"
                        class="btn-icon mr-50"
                        size="sm"
                        :title="t('Nhận xử lý')"
                        @click="onBeginningTaskClick(props.row)"
                      >
                        <feather-icon icon="BriefcaseIcon" />
                      </b-button>
                      <b-button
                        v-if="(props.row.status.value === '1' || props.row.status.value === '2' || props.row.status.value === '5') && $can('finish', 'task')"
                        v-b-tooltip.hover.v-info
                        v-b-modal.modal-finish-task
                        variant="info"
                        class="btn-icon mr-50"
                        size="sm"
                        :title="t('Hoàn thành')"
                        @click="onEditItem(props.row)"
                      >
                        <feather-icon icon="ThumbsUpIcon" />
                      </b-button>
                      <b-button
                        v-if="props.row.status.value === '3' && $can('review', 'task')"
                        v-b-tooltip.hover.v-success
                        v-b-modal.modal-review-task
                        variant="success"
                        class="btn-icon mr-50"
                        size="sm"
                        :title="t('Nghiệm thu')"
                        @click="onEditItem(props.row)"
                      >
                        <feather-icon icon="CheckSquareIcon" />
                      </b-button>
                    </template>
                  </table-actions>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ t('Hiển thị tối đa') }} </span>
                    <b-form-select
                      v-model="serverParams.perPage"
                      :options="['10', '20', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      {{ t('trên tổng số') }} {{ props.total }} {{ t('kết quả') }}
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="serverParams.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                <div class="text-center text-muted">
                  {{ t('Không có bản ghi nào!') }}
                </div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- modal -->
    <task-modal
      :item="item"
      @refetch-data="fetchData"
    />

    <finish-task-modal
      :item="item"
      @refetch-data="fetchData"
    />

    <review-task-modal
      :item="item"
      @refetch-data="fetchData"
    />

    <task-detail-modal :task-id="item ? item.id : null" />

  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BLink,
  BPagination,
  BFormSelect,
  BSpinner,
  BBadge,
  BButton,
  VBTooltip,
  VBModal,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import TableActions from '@/views/components/TableActions.vue';
import CrudButtons from '@/views/components/CrudButtons.vue';
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import SelectApartment from '@/views/components/SelectApartment.vue';
import SelectRoom from '@/views/components/SelectRoom.vue';
import SelectTaskStatus from '@/views/components/SelectTaskStatus.vue';
import CardStatistic from '@/views/components/CardStatistic.vue';
import SelectTaskType from '@/views/components/SelectTaskType.vue';
import { parseDateToString } from '@/auth/utils';
import SelectManager from '@/views/components/SelectManager.vue';
import SelectDate from '@/views/components/SelectDate.vue';
import TaskModal from '../modal/TaskModal.vue';
import FinishTaskModal from '../modal/FinishTaskModal.vue';
// eslint-disable-next-line import/no-cycle
import useTaskList from './useTaskList';
import TaskDetailModal from '../detail/TaskDetailModal.vue';
import ReviewTaskModal from '../review/ReviewTaskModal.vue';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BLink,
    BPagination,
    BFormSelect,
    BSpinner,
    BBadge,
    BButton,
    TaskModal,
    FinishTaskModal,
    ReviewTaskModal,
    VueGoodTable,
    TableActions,
    CrudButtons,
    SelectApartment,
    SelectRoom,
    SelectTaskStatus,
    CardStatistic,
    SelectTaskType,
    TaskDetailModal,
    SelectManager,
    SelectDate,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-modal": VBModal,
  },

  setup() {
    const {
      item,
      analytics,
      exportData,
      resetItem,
      columns,
      rows,
      selectedRows,
      searchTerm,
      apartment,
      room,
      status,
      type,
      isLoading,
      totalRecords,
      serverParams,
      performer,
      startDate,
      endDate,
      fetchData,
      fetchTasks,
      deleteTasks,
      createTask,
      refetchTasks,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      onEditItem,
      updateTaskStatus,
      resolvePerformerNames,
      t,
    } = useTaskList();

    return {
      item,
      analytics,
      exportData,
      resetItem,
      columns,
      rows,
      selectedRows,
      searchTerm,
      apartment,
      room,
      status,
      type,
      isLoading,
      totalRecords,
      serverParams,
      performer,
      startDate,
      endDate,
      fetchData,
      refetchTasks,
      fetchTasks,
      deleteTasks,
      createTask,
      t,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      onEditItem,
      updateTaskStatus,
      resolvePerformerNames,
      parseDateToString,
    };
  },

  created() {
    this.fetchData();
  },

  methods: {

    onDelete(task) {
      const deleteObjects = task && task.id > 0 ? [task] : this.selectedRows;
      this.deleteTasks(deleteObjects);
    },
    onBeginningTaskClick(task) {
      this.$bvModal
        .msgBoxConfirm(
          `Bạn có chắc chắn muốn nhận xử lý công việc này?`,
          {
            title: `Nhận xử lý việc`,
            okTitle: 'Nhận',
            cancelTitle: 'Huỷ',
            okVariant: 'warning',
            cancelVariant: 'outline-secondary',
          },
        )
        .then(value => {
          if (value) {
            this.updateTaskStatus({ id: task.id, status: 2 });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.task-list-container {
  .vgt-table {
      thead {
        tr {
          th {
            min-width: 120px !important;

            &:first-child {
              min-width: 50px !important;
            }

          }
        }
      }
    }
}
</style>
