<template>
  <b-modal
    id="modal-task"
    ref="refModal"
    :title="t('Công việc')"
    :ok-title="isSubmitting ? t('Đang lưu') : t('Lưu')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    size="lg"
    @show="onOpen"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      v-if="!isLoading"
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col :md="resolveColWidthIfDisableBed()">
            <select-apartment
              v-model="itemLocal.apartment"
              :default-value="itemLocal.apartment"
              :label="t('Tòa nhà')"
            />
          </b-col>
          <b-col :md="resolveColWidthIfDisableBed()">
            <select-room
              v-model="itemLocal.room"
              :filter-active="true"
              :apartment="itemLocal.apartment"
              :default-value="itemLocal.room"
              :label="t('Phòng')"
            />
          </b-col>
          <b-col
            v-if="resolveColWidthIfDisableBed(true) > 0"
            :md="resolveColWidthIfDisableBed()"
          >
            <select-bed
              v-model="itemLocal.bed"
              :filter-active="true"
              :room="itemLocal.room"
              :default-value="itemLocal.bed"
              :label="t('Giường')"
            />
          </b-col>
        </b-row>

        <!-- Title -->
        <validation-provider
          #default="validationContext"
          name="Tiêu đề"
          rules="required"
        >
          <b-form-group
            :label="t('Tiêu đề')"
          >
            <b-form-input
              id="task-title"
              v-model="itemLocal.title"
              :state="getValidationState(validationContext)"
              trim
              :placeholder="t('Sửa sự cố internet')"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Description -->
        <validation-provider
          #default="validationContext"
          name="Mô tả"
          rules="required"
        >
          <b-form-group
            :label="t('Mô tả')"
          >
            <b-form-textarea
              v-model="itemLocal.description"
              :state="getValidationState(validationContext)"
              rows="3"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <b-row>
          <b-col cols="4">
            <select-task-type
              v-model="itemLocal.type"
              :default-value="itemLocal.type"
            />
          </b-col>
          <b-col cols="4">
            <select-priority
              v-model="itemLocal.priorityObject"
              :label="t('Mức độ ưu tiên')"
              :default-value="itemLocal.priorityObject"
            />
          </b-col>

          <b-col cols="4">
            <!-- due Date -->
            <time-picker
              v-model="itemLocal.dueDate"
              :default-value="itemLocal.dueDate"
              :label="t('Hạn hoàn thành')"
            />
          </b-col>
          <b-col />
        </b-row>
        <b-row>
          <b-col cols="12">
            <!-- Assignee -->
            <select-manager
              v-model="itemLocal.performers"
              :label="t('Người thực hiện')"
              multiple
              :apartment="itemLocal.apartment"
              :default-value="itemLocal.performers"
            />
          </b-col>
        </b-row>
        <media-view
          v-model="itemLocal.attachments"
          :label="t('Đính kèm')"
          :initial-value="itemLocal.attachments"
          @on-delete-uploaded-file="onDeleteAttachment"
        />

        <!-- preview -->
        <!-- <gallery-images :photos="itemLocal.attachments" /> -->
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BForm,
  BFormInvalidFeedback,
  BRow,
  BCol,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectApartment from '@/views/components/SelectApartment.vue';
import SelectRoom from '@/views/components/SelectRoom.vue';
import SelectBed from '@/views/components/SelectBed.vue';
import TimePicker from '@/views/components/TimePicker.vue';
import SelectManager from '@/views/components/SelectManager.vue';
import MediaView from '@/views/components/media/MediaView.vue';
import SelectTaskType from '@/views/components/SelectTaskType.vue';
import SelectPriority from '@/views/components/SelectPriority.vue';
import useTaskModal from './useTaskModal';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,

    SelectApartment,
    SelectRoom,
    SelectBed,
    SelectManager,
    TimePicker,
    MediaView,
    SelectTaskType,
    SelectPriority,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onDeleteAttachment,
      onSubmit,
      resolveColWidthIfDisableBed,
      t,
      isLoading,
      onOpen,
    } = useTaskModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      onDeleteAttachment,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      resolveColWidthIfDisableBed,
      t,
      isLoading,
      onOpen,
    };
  },
};
</script>
