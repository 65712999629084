<template>
  <b-modal
    id="modal-review-task"
    ref="refModalReview"
    :title="t('Nghiệm thu công việc')"
    :ok-title="isSubmitting ? t('Đang lưu') : t('Lưu')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    size="lg"
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-alert
        show
        variant="primary"
      >
        <div class="alert-body">
          <feather-icon
            class="mr-25"
            icon="StarIcon"
          />
          <span class="ml-25">{{ t('Bạn đang tiến hành nghiệm thu công việc') }}: <strong>{{ itemLocal.title }}</strong>.</span>
        </div>
      </b-alert>
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-50"
          >
            Đánh giá kết quả xử lý
          </b-col>
          <b-col cols="6">
            <b-card
              no-body
              :class="`shadow-none border ${itemLocal.status ? 'bg-light-primary' : 'bg-light-secondary'} `"
              @click="setStatus(true)"
            >
              <b-card-header>
                <b-card-title>{{ t('Đạt yêu cầu') }}</b-card-title>
                <b-form-radio
                  v-model="itemLocal.status"
                  name="status"
                  :value="true"
                  class="custom-control-primary"
                />
              </b-card-header>
            </b-card>

          </b-col>
          <b-col>
            <b-card
              no-body
              :class="`shadow-none border ${!itemLocal.status ? 'bg-light-danger' : 'bg-light-secondary'} `"
              @click="setStatus(false)"
            >
              <b-card-header>
                <b-card-title>{{ t('Không đạt') }}</b-card-title>
                <b-form-radio
                  v-model="itemLocal.status"
                  name="status"
                  :value="false"
                  class="custom-control-danger"
                />
              </b-card-header>
            </b-card>

          </b-col>
        </b-row>
        <b-row v-if="itemLocal.status === false">
          <b-col>
            <!-- Description -->
            <validation-provider

              #default="validationContext"
              name="Mô tả"
              rules="required"
            >
              <b-form-group
                :label="t('Mô tả lý do chưa đạt')"
              >
                <template
                  v-slot:label
                >
                  {{ t('Mô tả lý do chưa đạt') }}
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-textarea
                  v-model="itemLocal.reviewNote"
                  :state="getValidationState(validationContext)"
                  rows="3"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="mb-50"
          >
            Khách hàng nhận xét
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card
              no-body
              :class="`shadow-none border bg-light-danger `"
              @click="setCustomerRating('2')"
            >
              <b-card-header>
                <b-card-title>{{ t('Không hài lòng') }}</b-card-title>
                <b-form-radio
                  v-model="itemLocal.customerRating"
                  name="customerRating"
                  :value="'2'"
                  class="custom-control-primary"
                />
              </b-card-header>
            </b-card>
          </b-col>
          <b-col>
            <b-card
              no-body
              :class="`shadow-none border bg-light-primary `"
              @click="setCustomerRating('4')"
            >
              <b-card-header>
                <b-card-title>{{ t('Hài lòng') }}</b-card-title>
                <b-form-radio
                  v-model="itemLocal.customerRating"
                  name="customerRating"
                  :value="'4'"
                  class="custom-control-danger"
                />
              </b-card-header>
            </b-card>

          </b-col>
          <b-col>
            <b-card
              no-body
              :class="`shadow-none border bg-light-success `"
              @click="setCustomerRating('5')"
            >
              <b-card-header>
                <b-card-title>{{ t('Rất hài lòng') }}</b-card-title>
                <b-form-radio
                  v-model="itemLocal.customerRating"
                  name="customerRating"
                  :value="'5'"
                  class="custom-control-danger"
                />
              </b-card-header>
            </b-card>

          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <!-- Description -->
            <validation-provider

              #default="validationContext"
              name="Nhận xét của khách hàng"
              rules=""
            >
              <b-form-group
                :label="t('Nhận xét của khách hàng')"
              >
                <b-form-textarea
                  v-model="itemLocal.customerReview"
                  :state="getValidationState(validationContext)"
                  rows="3"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- <media-view
          v-if="itemLocal.status === false"
          v-model="itemLocal.reviewAttachments"
          label="Đính kèm"
          :initial-value="itemLocal.reviewAttachments"
        /> -->

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormTextarea,
  BForm,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormRadio,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import useReviewTaskModal from './useReviewTaskModal';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormTextarea,
    BForm,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormRadio,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalReview,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      setStatus,
      t,
      setCustomerRating,
    } = useReviewTaskModal(props, emit, refFormObserver);

    return {
      refModalReview,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      setStatus,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      t,
      setCustomerRating,
    };
  },
};
</script>
